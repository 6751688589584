(function(){
    'use strict';

    angular
        .module('app')
        .directive('contactForm', contactForm);

    function contactForm() {
        var directive = {
            templateUrl: '/app/contact/contactFormView.html',
            restrict: 'EA'
        };
        return directive;
    }

})();