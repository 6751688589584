(function(){
    'use strict';

    angular
    .module('app')
    .controller('FormController', FormController);

    FormController.$inject = ['insuranceService','$timeout', '$filter'];

    function FormController(insuranceService,$timeout, $filter){
        var vm = this;

        vm.billing = "HASTA 150.000€";
        vm.billingChanged = billingChanged;
        vm.billingOptions = [];
        vm.company = "";
        vm.compensation = 0;
        vm.compensationOptions = [];
        vm.compensationSelected = compensationSelected;
        vm.facturacion = 0;        
        vm.facturacionSelected = facturacionSelected;        
        vm.franchise = 0;
        vm.franchiseOptions = [];
        vm.franchiseSelected = franchiseSelected;
        vm.graduatedYears = 1;
        vm.graduatedYearsChanged = graduatedYearsChanged;
        vm.getBilling = getBilling;
        vm.insurance = {};
        vm.insurancePolicy = false;
        vm.profile = "Empresa";
        vm.profileChanged = profileChanged;
        vm.profileUpdated = "Empresa";
        vm.resetForm = resetForm;
        vm.securityAndHealth = false;
        vm.setShowInsurance = setShowInsurance;
        vm.showInsurance = false;
        vm.sinisters = 0;
        vm.sinistersSelected = sinistersSelected;
        vm.workers = 0;
        vm.workersChanged = workersChanged;
        vm.workingPlace = "España";
        vm.workingPlaceOptions = [];

        function billingChanged(billingSelected){
            if(billingSelected === undefined)
                vm.billing = undefined;
            else{ 
                vm.billing = billingSelected;
                vm.getBilling();
                vm.setShowInsurance(true);
            }
        }

        function compensationSelected(compensation){
            if(compensation === undefined){
                compensation = undefined;
            }else{
                vm.compensation = parseInt(compensation);
            }
        }

        function facturacionSelected(facturacion){
            if(facturacion === undefined){
                facturacion = undefined;
            }else{
                vm.getBilling();
                vm.facturacion = parseInt(facturacion);
            }
        }

        function franchiseSelected(franchise){
            if(franchise === undefined)
                vm.franchise = undefined;
            else
                vm.franchise = franchise;
        }

        function getBilling(){
            if(vm.profile === 'Empresa')
                vm.graduatedYears = 1;
            var billingData = {
                workers: vm.workers,
                profile: vm.profile,
                graduatedYears: vm.graduatedYears,
                securityAndHealth: vm.securityAndHealth,
                billing: vm.billing,
                compensation: vm.compensation

            };
            insuranceService.getBilling(billingData).then(function(response){
                var comp = false;
                vm.billingOptions = response.billing;
                vm.compensationOptions = response.compensation;
                vm.franchiseOptions = response.franchise;
                vm.company = response.company;
                vm.workingPlaceOptions = [];
                if(vm.company === 'aig'){
                    vm.workingPlaceOptions.push({place:'Mundial excepto EEUU y Canadá'});
                    vm.workingPlaceOptions.push({place:'EEUU y Canada'});
                }else{
                    vm.workingPlaceOptions.push({place:'España'});
                    vm.workingPlaceOptions.push({place:'Unión Europea'});
                    vm.workingPlaceOptions.push({place:'Mundial excepto EEUU y Canadá'});
                    vm.workingPlaceOptions.push({place:'EEUU y Canada'});
                }
                $timeout(function(){
                    jQuery('#billing').data("selectBox-selectBoxIt").refresh();
                    jQuery('#compensation').data("selectBox-selectBoxIt").refresh();
                    jQuery('#franchise').data("selectBox-selectBoxIt").refresh();
                    jQuery('#workingPlace').data("selectBox-selectBoxIt").refresh();
                });
                if(vm.profile===vm.profileUpdated)
                    vm.billing = response.billingSelected;
                    
                else
                    vm.billing = response.billing[0].facturacion;
                    
                  

                for (var i = vm.compensationOptions.length - 1; i >= 0; i--) {
                             if(vm.compensationOptions[i].limite_capital==response.compensationSelected){
                                                    vm.compensation = response.compensationSelected.toString();
                                    comp =true

                             }
                         }   
                if(!comp)
                                    vm.compensation = vm.compensationOptions[0].limite_capital.toString();
  
                    
                
                vm.profileUpdated=vm.profile;
                vm.franchise = '0';
                
                vm.workingPlace = vm.workingPlaceOptions[0].place;
            });
        }

        

        function graduatedYearsChanged(yearsSelected){
            if(yearsSelected === undefined)
                vm.graduatedYears = 1;
            else 
                vm.graduatedYears = parseInt(yearsSelected);
            vm.getBilling();
        }

        function profileChanged(){
            vm.getBilling();
        }

        function resetForm(form){
            if(form) {
                vm.workers = 1;
                vm.insurancePolicy = null;
                vm.securityAndHealth = null;
                form.$setPristine();
                form.$setUntouched();
            }
        }

        function setShowInsurance(value){
            vm.showInsurance = value;
        }

        function sinistersSelected(sinisters){
            if(sinisters === undefined)
                vm.sinisters = undefined;
            else 
                vm.sinisters = parseInt(sinisters);
        }

        function workersChanged(){
            vm.getBilling();
        }

        function workingPlaceSelected(workingPlace){                
            if(workingPlace === undefined)
                vm.workingPlace = undefined;
            else 
                vm.workingPlace = workingPlace;
        }

    }
})();

