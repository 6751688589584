(function() {
'use strict';

    angular
        .module('app')
        .controller('ContactFormController', ContactFormController);

    ContactFormController.$inject = ['contactService'];

    function ContactFormController(contactService) {
        var vm = this;

        vm.response = false;
        vm.submitForm = submitForm;
        vm.sendMail = sendMail;
        vm.user = {
            agreement: false
        };

        vm.button = contactService.getContactBtn;


        function resetForm(form){
             if(form) {
                vm.user = {};
                form.$setPristine();
                form.$setUntouched();
            }
        }

        function sendMail(user,form){
            
            if(user.commentaries === undefined)
                user.commentaries = "";
            return contactService.sendMail(user)
                .then(function(response){
                    console.log(response);
                    vm.response = response;
                    if(response === true){
                        resetForm(form);
                    }else{
                        //TODO
                    }

                });
        }

        function submitForm(form){

            sendMail(vm.user,form);

        };
        
    }
})();