(function(){
    'use strict';

    angular
    .module('app')
    .controller('AppController', AppController);

    AppController.$inject = ['insuranceService','contactService'];

    function AppController(insuranceService, contactService, $scope){
         var vm = this;

         vm.changeView = changeView;
         vm.closePopup = closePopup;
         vm.showPopup = showPopup;
         vm.createInsurance = createInsurance;
         vm.coberturas = [];
         vm.getInsurance = getInsurance;
         vm.getPolicy = getPolicy;
         vm.getSecurity = getSecurity;
         vm.img = '';
         vm.insurance = {
             price: 0
         };
         vm.insuranceEmpty = true;
         vm.insuranceError = null;
         vm.view = 'mainView';

        
         function showPopup(popup,sinisters)
         {
            if(popup === '#myModal'){
                if(sinisters>0)
                    jQuery(popup).modal('show');
            }else{
                jQuery(popup).modal('show');
            }
            
         }
         function closePopup(modal)
         {
            jQuery(modal).modal('hide');
         }
         function changeView(view,btn){
            var page ='/';
            jQuery( "body" ).scrollTop( 0 );
            if(btn !== undefined)
                contactService.setContactBtn(btn);

            if(view!='mainView')
                page = view.replace('View','');

            if(view=='rateView')
            {
                page='/calculadora';
            }
            if(view=='contactForm')
            {
                if (btn=='Contacto')
                    page = '/contacto';
                else
                    page = '/pedir-proyecto';
            }
            ga('send', 'pageview', page);

            vm.view = view;
         }

         function createInsurance(insurance){
             if(insurance.securityAndHealth === null)
                insurance.securityAndHealth = false;
            if(insurance.insurancePolicy === null)
                insurance.insurancePolicy = false;
            var saveInsurance = {
                        name: '',
                        price: 0,
                        facturacion: insurance.facturacion,
                        billing: insurance.billing,
                        profile: insurance.profile,
                        graduatedYears: insurance.graduatedYears,
                        workers: insurance.workers,
                        compensation: insurance.compensation,
                        insurancePolicy: insurance.insurancePolicy,
                        securityAndHealth: insurance.securityAndHealth,
                        franchise: insurance.franchise,
                        sinisters: insurance.sinisters,
                        workingPlace: insurance.workingPlace, 
                        company: insurance.company
            };
            return saveInsurance;
         }

         function getInsurance(insurance){
            
            vm.insurance = createInsurance(insurance);
            if(vm.insurance.sinisters === 0){
                
            if(!(vm.insurance.company === "aig" && vm.insurance.securityAndHealth===1)){
                if((vm.insurance.company === "aig" && vm.insurance.workingPlace === "Mundial excepto EEUU y Canadá") || (vm.insurance.company === "lloyds" && vm.insurance.workingPlace === "España")
                || (vm.insurance.company === "lloyds" && vm.insurance.workingPlace === "Unión Europea")){
                    return insuranceService.calculateInsurance(vm.insurance)
                    .then(function(response) {
                        if(response.message){
                            vm.insuranceEmpty = true;
                            vm.insuranceError = response.message;
                            vm.error = true;
                        }else{
                            vm.error = false;
                            vm.insuranceError = null;
                            vm.insuranceEmpty = false;
                            vm.insurance.name = response.company;
                            vm.insurance.price = response.price.value;
                            vm.coberturas = response.coberturas;
                            if(response.company === 'aig')
                                vm.img = 'aig.png';
                            else 
                                vm.img = 'Lloyds_logo.png';

                            insuranceService.setInsurance(vm.insurance);
                            changeView('rateView');
                        }
                    });
                }
                else{
                    insuranceService.setInsurance(vm.insurance);
                    vm.showPopup('#myModal2',vm.insurance.sinisters)
                }
            }else{
                insuranceService.setInsurance(vm.insurance);
                vm.showPopup('#myModal3',vm.insurance.sinisters);
            }
            }else{
                insuranceService.setInsurance(vm.insurance);
                vm.showPopup('#myModal',vm.insurance.sinisters);
            }
        }

        function getPolicy(){
            if(vm.insurance.insurancePolicy === 1)
                return "Sí";
            return "No";
        }

        function getSecurity(){
            if(vm.insurance.securityAndHealth === 1)
                return "Sí";
            return "No";
        }
         
    }

})();