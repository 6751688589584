(function(){
    angular
        .module('app')
        .directive('icheckDirective', icheckDirective);

    icheckDirective.$inject = ['$timeout', '$parse']

    function icheckDirective($timeout, $parse){
        return {
            require: 'ngModel',
            link: function ($scope, element, $attrs, ngModel) {
                return $timeout(function () {
                    var value = $attrs.value;
                    var $element = $(element);

                    $element.iCheck({
                            checkboxClass: 'icheckbox_minimal-red',

                    });
                    
                    if($element.attr('checked')){
                    
                           $timeout(function(){
                            
                                $element.click();
                                //ngModel.$setViewValue(true);
                           });
                    }

                    // If the model changes, update the iCheck control.
                    $scope.$watch($attrs.ngModel, function (newValue) {
                        $element.iCheck('update');
                    });

                    // If the iCheck control changes, update the model.
                    $element.on('ifChanged', function (event) {
                        
                        if ($element.attr('type') === 'radio' && $attrs.ngModel) {
                            $scope.$apply(function () {
                                ngModel.$setViewValue(value);
                            });
                        }
                    if ($(element).attr('type') === 'checkbox' && $attrs['ngModel']) {
                        $scope.$apply(function() {
                            return ngModel.$setViewValue(event.target.checked);
                        });
                        }

                    });

                });
            }
        };
    }
})();