(function() {
    angular
        .module('app')
        .directive('changeMenu', changeMenu);


function changeMenu() {
    var directive = {
        restrict: 'EA',
        link: linkFunc,
    };

    return directive;

    function linkFunc(scope, el, attr,ctrl) {
        el.on('click', function(){
            var id = $(this).attr('id');
            if(id === "rate-menu-link"){
                $("#menu-nav").find("li").removeClass("active");
                $("#rate-menu").addClass("active");
            }else if(id === "est-menu-link"){
                $("#menu-nav").find("li").removeClass("active");
                $("#estimation-menu").addClass("active");
            }else{
                $("#menu-nav").find("li").removeClass("active");
                $("#contact-menu").addClass("active");
            }
        });
    }
}
})();