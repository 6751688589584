(function(){
    'use strict';

    angular
        .module('app')
        .directive('insuranceRateView', insuranceRateView);

    function insuranceRateView() {
        var directive = {
            templateUrl: '/app/insurance/insuranceRateView.html',
            restrict: 'EA'
        };
        return directive;
    }

})();