(function() {
    angular
        .module('app')
        .directive('setMenu', setMenu);


function setMenu() {
    var directive = {
        restrict: 'EA',
        link: linkFunc,
        scope: {
            profile: '@'
        },
        controller: 'FormController',
        controllerAs: 'vm',
        bindToController: true // because the scope is isolated
    };

    return directive;

    function linkFunc(scope, el, attr,ctrl) {
        el.on('click', function(){
            var id = $(this).attr('id');
            if(id === "getInTouch"){
                $("#menu-nav").find("li").removeClass("active");
                $("#contact-menu").addClass("active");
            }else if(id === "calcInsurance"){
                if(scope.vm.profile === 'Empresa'){
                    $("#menu-nav").find("li").removeClass("active");
                    $("#estimation-menu").addClass("active");
                }
            }else{
                $("#menu-nav").find("li").removeClass("active");
                $("#rate-menu").addClass("active");
            }
        });
    }
}
})();