(function() {
    angular
        .module('app')
        .directive('resetSelect', resetSelect);


function resetSelect() {
    var directive = {
        restrict: 'EA',
        link: linkFunc,
    };

    return directive;

    function linkFunc(scope, el, attr) {
        el.on('click', function(){

            var selectBox = $("#profile").selectBoxIt();
            selectBox.selectBoxIt('selectOption', 0);
            selectBox = $("#billing").selectBoxIt();
            selectBox.selectBoxIt('selectOption', 0);
            selectBox = $("#compensation").selectBoxIt();
            selectBox.selectBoxIt('selectOption', 0);
            selectBox = $("#graduatedYears").selectBoxIt();
            selectBox.selectBoxIt('selectOption', 0);
            selectBox = $("#sinisters").selectBoxIt();
            selectBox.selectBoxIt('selectOption', 0);
            selectBox = $("#franchise").selectBoxIt();
            selectBox.selectBoxIt('selectOption', 0);
            selectBox = $("#workingPlace").selectBoxIt();
            selectBox.selectBoxIt('selectOption', 0);

        });
    }
}
})();