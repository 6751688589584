(function() {
'use strict';

    angular
        .module('app')
        .factory('contactService', contactService);

    

    contactService.$inject = ['$http','insuranceService'];

    function contactService($http, insuranceService) {

        var url = '/ajax/contacto';
        var btn = '';

        var service = {
            getContactBtn: getContactBtn,
            sendMail:sendMail,
            setContactBtn: setContactBtn
        };
        
        return service;

        function getContactBtn(){
            return btn;
        }

        function sendMail(user) {

            var insurance = insuranceService.getInsurance();
            var button = btn;
            var data = {
                user: user,
                insurance: insurance,
                button: button
            };
             return $http({
                method  : 'POST',
                url     : url,
                data    : data

            })
                .then(sendMailSuccess)
                .catch(sendMailFailed);
            
            function sendMailSuccess(data){
                return data.data.mail;


            }

            function sendMailFailed(data){
                return false;
            }
        }

        function setContactBtn(b){
            btn = b;
        }
    }
})();