(function(){
     'use strict';

     angular
        .module('app')
        .factory('insuranceService', insuranceService);

    insuranceService.$inject = ['$http'];

    function insuranceService($http){
        var url='/ajax/tarifas';
        var insurance = null;

        var service= {
            calculateInsurance: calculateInsurance,
            getBilling: getBilling,
            getInsurance: getInsurance,
            setInsurance: setInsurance
        };

        return service;

        function calculateInsurance(insurance){
            return $http({
                method  : 'POST',
                url     : url,
                data    : insurance
            })
                .then(calculateInsuranceSuccess)
                .catch(calculateInsuranceFailed);
            
            function calculateInsuranceSuccess(data){      
                return data.data.data;
            }

            function calculateInsuranceFailed(){
                return false;
            }
        }

        function getBilling(billingData){
            var billingUrl= '/ajax/billing';
             return $http({
                method  : 'POST',
                url     : billingUrl,
                data    : billingData
            })
                .then(getBillingSuccess)
                .catch(getBillingFailed);
            
            function getBillingSuccess(data){      
                return data.data.data;
            }

            function getBillingFailed(data){
                console.log(data);
                return false;
            }
        }

        function getInsurance(){
            return insurance;
        }

        function setInsurance(insu){
            insurance = insu;
        }
        
    }
})();